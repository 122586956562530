.main_chat{
    border: 2px solid rgb(246, 245, 245);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.chat_header{
    height: 15%;
    width: 100%;
    /* border: 1px solid orangered; */
}
.chats{
    height: 85%;
    width: 100%;
    /* border: 2px solid green; */
    display: flex;
}
.max_chats{
    /* border: 2px solid blue; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
}
.sessions{
    height: 100%;
    width: 40%;
    overflow-y: auto;
    background-color:rgb(245, 240, 240);
}
.max_sessions{
    height: 100%;
    width: 20%;
    overflow-y: auto;
    background-color:rgb(245, 240, 240);
}
.messages{
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    /* width: fit-content; */
    width: 100%;
    overflow: hidden;
}
.chat_messages{
    /* height: 85%; */
    flex:8;
    width: 100%;
    overflow-y: auto;
}
.chat_input{
    /* height: 15%; */
    width: 100%;
    /* flex: 2; */
}
.chatbox{
    border: 2px solid rgb(246, 245, 245);
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
}
.text {
    position: relative;
    opacity: 1; /* Set the opacity to 1 for elements with the "text" class */
}
.user_message{
    display: flex;
    align-items: center;
}
.message {
    margin: 0.313rem;
    padding: 0.625rem;
     /* width: 80%; */
    flex: 1;
    text-align: justify;
} 
.message.right {
    /* Styles for messages on the right */
    float: right;
    background-color:white;
    color: black;
    align-self: flex-end;
    font-size: medium;
    border-radius: 0.5rem 0.5rem 0rem 0.5rem;
    border: 1px solid blue;
}
.user_image{
    /* border: 2px solid blue; */
    border-radius: 3.125rem;
    padding: 0.188rem;
}
.bot_message{
    display: flex;
    margin-top: 0.313rem;
    gap: 0.313rem;
}
.bot_image{
    width:1.875rem;
    height: 1.25rem;
    padding-left: 0.313rem;
}
.message.left {
    /* Styles for messages on the left */
    background-color: white;
    border: 0.02rem solid blue;
    float: left;
    align-self: flex-start;
    border-radius: 0px 0.5rem .5rem .5rem;
    color: black;
    font-size: medium;
    overflow-x: auto;
}
.fading{
    animation:  fadeIn 1s;
}
@keyframes fadeIn{
    0% {opacity: 0;}
    100% {opacity:1}
}
div {
    white-space: pre-line;
}

.sessions::-webkit-scrollbar {
	width: 4px;
	background-color: #F5F5F5;
    cursor: pointer;
}
.sessions::-webkit-scrollbar-thumb {
    border-radius: 5px;
	background-color: #8054ae;
    cursor: pointer;
}
.chat_messages::-webkit-scrollbar {
	width: 4px;
	background-color: #F5F5F5;
    cursor: pointer;
}

.chat_messages::-webkit-scrollbar-thumb {
    border-radius: 5px;
	background-color: #8054ae;
    cursor: pointer;
}
.left::-webkit-scrollbar {
	height: 4px;
	background-color: #F5F5F5;
    cursor: pointer;
}

.left::-webkit-scrollbar-thumb {
    border-radius: 5px;
	background-color: #8054ae;
    cursor: pointer;
}
.message.status_check{
    /* background-color: rgb(144, 240, 144); */
    color: green;
    text-align: left;
    border: 0.02rem solid green;
}