.main_chatbot{
    height: 69.44%; /* 500px*/
    width: 40%;/* 320px */
    position: absolute;
    bottom: 5%;
    right: 5%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .logout_bt{
    position: absolute;
    right: 7%;
    top: 5%;
  }
  .chatbox__button{
    height: 10%; /* 500px*/
    width: 10%;
    position: absolute;
    bottom: 5%;
    right: 1%;
    border: none;
  }
  .chatbox__button button {
    cursor: pointer;
}
  .max_chats{
    height: 90%; /* 500px*/
    width: 90%;/* 320px */
    position: absolute;
    bottom: 5%;
    right: 5%;
    /* border: 2px solid blue; */ 
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

